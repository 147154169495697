/* eslint-disable */


// Programatically generated on Thu Apr 10 2025 21:12:51 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'subjects_v2',
    'attributes': {
      'featured_as': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'domain': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'hidden': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'banner': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'should_show_label_table': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'code': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'alternate_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'footer_text': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'free_response_questions_published': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'subscriptions_prices': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'logo_image_url': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'url_slug': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'description': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'group': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'authoring_guide_levels_v1': {
        'type': 'one-to-many'
      },
      'supplements_v1': {
        'type': 'one-to-many'
      },
      'guides_v1': {
        'type': 'one-to-many'
      },
      'subscriptions_v1': {
        'type': 'one-to-many'
      },
      'exams_v1': {
        'type': 'one-to-many'
      },
      'standard_sets_v1': {
        'type': 'one-to-many'
      },
      'authoring_supplements_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v1': {
        'type': 'one-to-many'
      },
      'guide_levels_v2': {
        'type': 'one-to-many'
      },
      'curriculum_area_v1': {
        'type': 'one-to-one'
      },
      'subject_faqs_v1': {
        'type': 'one-to-many'
      },
      'school_license_subject_groups_v1': {
        'type': 'one-to-many'
      },
      'authoring_exams_v1': {
        'type': 'one-to-many'
      },
      'question_sets_v1': {
        'type': 'one-to-many'
      },
      'authoring_labels_v1': {
        'type': 'one-to-many'
      },
      'student_classrooms_v1': {
        'type': 'one-to-many'
      },
      'authoring_question_sets_v1': {
        'type': 'one-to-many'
      },
      'classrooms_v1': {
        'type': 'one-to-many'
      },
      'labels_v1': {
        'type': 'one-to-many'
      },
      'authoring_guides_v1': {
        'type': 'one-to-many'
      },
      'search_standards_v1': {
        'type': 'one-to-many'
      },
      'school_licenses_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'classrooms': Empty,
    'authoring_question_sets': Empty,
    'featured_as': Empty,
    'domain': Empty,
    'student_classrooms': Empty,
    'authoring_labels': Empty,
    'question_sets': Empty,
    'hidden': Empty,
    'name': Empty,
    'authoring_exams': Empty,
    'banner': Empty,
    'should_show_label_table': Empty,
    'code': Empty,
    'school_license_subject_groups': Empty,
    'alternate_name': Empty,
    'subject_faqs': Empty,
    'footer_text': Empty,
    'free_response_questions_published': Empty,
    'curriculum_area': Empty,
    'guide_levels': Empty,
    'authoring_supplements': Empty,
    'standard_sets': Empty,
    'subscriptions_prices': Empty,
    'exams': Empty,
    'subscriptions': Empty,
    'guides': Empty,
    'updated_at': Empty,
    'logo_image_url': Empty,
    'inserted_at': Empty,
    'authoring_guide_levels': Empty,
    'supplements': Empty,
    'id': Empty,
    'url_slug': Empty,
    'description': Empty,
    'relationshipMeta': Empty,
    'school_licenses': Empty,
    'group': Empty,
    'search_standards': Empty,
    'authoring_guides': Empty,
    'labels': Empty,
    'meta': new Map({
    'count_of_easy_guesses': Empty,
    'count_of_medium_questions_answered': Empty,
    'search_relevance': Empty,
    'matching_guide_levels_search_count': Empty,
    'sum_of_medium_guesses_points_earned': Empty,
    'count_of_school_teachers': Empty,
    'populated_metadata_fields': Empty,
    'has_access_via_subscription': Empty,
    'sum_of_difficult_guesses_points_earned': Empty,
    'count_of_practice_exams': Empty,
    'count_of_easy_questions': Empty,
    'count_of_difficult_questions_answered': Empty,
    'count_of_free_response_questions': Empty,
    'count_of_school_students': Empty,
    'restrict_free_practice': Empty,
    'count_of_easy_correct_guesses': Empty,
    'content_discovery_question_count': Empty,
    'count_of_difficult_guesses': Empty,
    'sum_of_easy_guesses_time_elapsed': Empty,
    'count_of_guides': Empty,
    'has_restricted_free_practice': Empty,
    'sum_of_easy_guesses_points_earned': Empty,
    'count_of_school_guesses': Empty,
    'has_access_via_license_as_student': Empty,
    'count_of_medium_guesses': Empty,
    'count_of_school_classrooms': Empty,
    'count_of_school_assignments': Empty,
    'count_of_difficult_questions': Empty,
    'count_of_easy_questions_answered': Empty,
    'included_in_personalization_settings': Empty,
    'count_of_questions': Empty,
    'belongs_to_standard': Empty,
    'count_of_difficult_correct_guesses': Empty,
    'count_of_school_incorrect_guesses': Empty,
    'meta_student_id': Empty,
    'sum_of_difficult_guesses_time_elapsed': Empty,
    'has_access_via_license_as_teacher': Empty,
    'sum_of_medium_guesses_time_elapsed': Empty,
    'count_of_medium_questions': Empty,
    'count_of_school_correct_guesses': Empty,
    'user_has_access': Empty,
    'count_of_medium_correct_guesses': Empty,
    'count_of_used_seats_by_subject': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'featured_as': '',
    'domain': '',
    'name': '',
    'banner': '',
    'code': '',
    'alternate_name': '',
    'footer_text': '',
    'logo_image_url': '',
    'id': '',
    'url_slug': '',
    'description': '',
    'group': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'classrooms': new List(),
    'authoring_question_sets': new List(),
    'student_classrooms': new List(),
    'authoring_labels': new List(),
    'question_sets': new List(),
    'authoring_exams': new List(),
    'school_license_subject_groups': new List(),
    'meta': new Map(),
    'hidden': false,
    'should_show_label_table': false,
    'subject_faqs': new List(),
    'free_response_questions_published': false,
    'guide_levels': new List(),
    'authoring_supplements': new List(),
    'standard_sets': new List(),
    'subscriptions_prices': new List(),
    'exams': new List(),
    'subscriptions': new List(),
    'guides': new List(),
    'authoring_guide_levels': new List(),
    'supplements': new List(),
    'school_licenses': new List(),
    'search_standards': new List(),
    'authoring_guides': new List(),
    'labels': new List(),
    'curriculum_area': new Map(),
    'relationshipMeta': new Map()
  }
);

export class SubjectModelV2 extends GenericModel(ModelRecord, ModelConfig, 'SubjectModelV2') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptySubjectModelV2Model = _emptySubjectModelV2Model ? _emptySubjectModelV2Model : new SubjectModelV2(DefaultModel);
    return _emptySubjectModelV2Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'SubjectModelV2';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'subject_v2';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getAlternateName() {
    const value = this.getValueForAttribute('alternate_name');
    return value;
  }

  setAlternateName(value) {
    return this.setField('alternate_name', value);
  }

  /**
   * @type {string}
   */
  getBanner() {
    const value = this.getValueForAttribute('banner');
    return value;
  }

  setBanner(value) {
    return this.setField('banner', value);
  }

  /**
   * @type {string}
   */
  getCode() {
    const value = this.getValueForAttribute('code');
    return value;
  }

  setCode(value) {
    return this.setField('code', value);
  }

  /**
   * @type {string}
   */
  getDescription() {
    const value = this.getValueForAttribute('description');
    return value;
  }

  setDescription(value) {
    return this.setField('description', value);
  }

  /**
   * @type {string}
   */
  getDomain() {
    const value = this.getValueForAttribute('domain');
    return value;
  }

  setDomain(value) {
    return this.setField('domain', value);
  }

  /**
   * @type {string}
   */
  getFeaturedAs() {
    const value = this.getValueForAttribute('featured_as');
    return value;
  }

  setFeaturedAs(value) {
    return this.setField('featured_as', value);
  }

  /**
   * @type {string}
   */
  getFooterText() {
    const value = this.getValueForAttribute('footer_text');
    return value;
  }

  setFooterText(value) {
    return this.setField('footer_text', value);
  }

  /**
   * @type {boolean}
   */
  isFreeResponseQuestionsPublished() {
    const value = this.getValueForAttribute('free_response_questions_published');
    return value;
  }

  setFreeResponseQuestionsPublished(value) {
    return this.setField('free_response_questions_published', value);
  }

  /**
   * @type {string}
   */
  getGroup() {
    const value = this.getValueForAttribute('group');
    return value;
  }

  setGroup(value) {
    return this.setField('group', value);
  }

  /**
   * @type {boolean}
   */
  isHidden() {
    const value = this.getValueForAttribute('hidden');
    return value;
  }

  setHidden(value) {
    return this.setField('hidden', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getLogoImageUrl() {
    const value = this.getValueForAttribute('logo_image_url');
    return value;
  }

  setLogoImageUrl(value) {
    return this.setField('logo_image_url', value);
  }

  /**
   * @type {string}
   */
  getName() {
    const value = this.getValueForAttribute('name');
    return value;
  }

  setName(value) {
    return this.setField('name', value);
  }

  /**
   * @type {boolean}
   */
  isShouldShowLabelTable() {
    const value = this.getValueForAttribute('should_show_label_table');
    return value;
  }

  setShouldShowLabelTable(value) {
    return this.setField('should_show_label_table', value);
  }

  /**
   * @type {map[]}
   */
  getSubscriptionsPrices() {
    const value = this.getValueForAttribute('subscriptions_prices');
    return value;
  }

  setSubscriptionsPrices(value) {
    return this.setField('subscriptions_prices', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUrlSlug() {
    const value = this.getValueForAttribute('url_slug');
    return value;
  }

  setUrlSlug(value) {
    return this.setField('url_slug', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      isBelongsToStandard() {
        return this.getMeta().isBelongsToStandard();
      }
    
      getContentDiscoveryQuestionCount() {
        return this.getMeta().getContentDiscoveryQuestionCount();
      }
    
      getCountOfDifficultCorrectGuesses() {
        return this.getMeta().getCountOfDifficultCorrectGuesses();
      }
    
      getCountOfDifficultGuesses() {
        return this.getMeta().getCountOfDifficultGuesses();
      }
    
      getCountOfDifficultQuestions() {
        return this.getMeta().getCountOfDifficultQuestions();
      }
    
      getCountOfDifficultQuestionsAnswered() {
        return this.getMeta().getCountOfDifficultQuestionsAnswered();
      }
    
      getCountOfEasyCorrectGuesses() {
        return this.getMeta().getCountOfEasyCorrectGuesses();
      }
    
      getCountOfEasyGuesses() {
        return this.getMeta().getCountOfEasyGuesses();
      }
    
      getCountOfEasyQuestions() {
        return this.getMeta().getCountOfEasyQuestions();
      }
    
      getCountOfEasyQuestionsAnswered() {
        return this.getMeta().getCountOfEasyQuestionsAnswered();
      }
    
      getCountOfFreeResponseQuestions() {
        return this.getMeta().getCountOfFreeResponseQuestions();
      }
    
      getCountOfGuides() {
        return this.getMeta().getCountOfGuides();
      }
    
      getCountOfMediumCorrectGuesses() {
        return this.getMeta().getCountOfMediumCorrectGuesses();
      }
    
      getCountOfMediumGuesses() {
        return this.getMeta().getCountOfMediumGuesses();
      }
    
      getCountOfMediumQuestions() {
        return this.getMeta().getCountOfMediumQuestions();
      }
    
      getCountOfMediumQuestionsAnswered() {
        return this.getMeta().getCountOfMediumQuestionsAnswered();
      }
    
      getCountOfPracticeExams() {
        return this.getMeta().getCountOfPracticeExams();
      }
    
      getCountOfQuestions() {
        return this.getMeta().getCountOfQuestions();
      }
    
      getCountOfSchoolAssignments() {
        return this.getMeta().getCountOfSchoolAssignments();
      }
    
      getCountOfSchoolClassrooms() {
        return this.getMeta().getCountOfSchoolClassrooms();
      }
    
      getCountOfSchoolCorrectGuesses() {
        return this.getMeta().getCountOfSchoolCorrectGuesses();
      }
    
      getCountOfSchoolGuesses() {
        return this.getMeta().getCountOfSchoolGuesses();
      }
    
      getCountOfSchoolIncorrectGuesses() {
        return this.getMeta().getCountOfSchoolIncorrectGuesses();
      }
    
      getCountOfSchoolStudents() {
        return this.getMeta().getCountOfSchoolStudents();
      }
    
      getCountOfSchoolTeachers() {
        return this.getMeta().getCountOfSchoolTeachers();
      }
    
      getCountOfUsedSeatsBySubject() {
        return this.getMeta().getCountOfUsedSeatsBySubject();
      }
    
      hasAccessViaLicenseAsStudent() {
        return this.getMeta().hasAccessViaLicenseAsStudent();
      }
    
      hasAccessViaLicenseAsTeacher() {
        return this.getMeta().hasAccessViaLicenseAsTeacher();
      }
    
      hasAccessViaSubscription() {
        return this.getMeta().hasAccessViaSubscription();
      }
    
      hasRestrictedFreePractice() {
        return this.getMeta().hasRestrictedFreePractice();
      }
    
      isIncludedInPersonalizationSettings() {
        return this.getMeta().isIncludedInPersonalizationSettings();
      }
    
      getMatchingGuideLevelsSearchCount() {
        return this.getMeta().getMatchingGuideLevelsSearchCount();
      }
    
      getMetaStudentId() {
        return this.getMeta().getMetaStudentId();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      isRestrictFreePractice() {
        return this.getMeta().isRestrictFreePractice();
      }
    
      getSearchRelevance() {
        return this.getMeta().getSearchRelevance();
      }
    
      getSumOfDifficultGuessesPointsEarned() {
        return this.getMeta().getSumOfDifficultGuessesPointsEarned();
      }
    
      getSumOfDifficultGuessesTimeElapsed() {
        return this.getMeta().getSumOfDifficultGuessesTimeElapsed();
      }
    
      getSumOfEasyGuessesPointsEarned() {
        return this.getMeta().getSumOfEasyGuessesPointsEarned();
      }
    
      getSumOfEasyGuessesTimeElapsed() {
        return this.getMeta().getSumOfEasyGuessesTimeElapsed();
      }
    
      getSumOfMediumGuessesPointsEarned() {
        return this.getMeta().getSumOfMediumGuessesPointsEarned();
      }
    
      getSumOfMediumGuessesTimeElapsed() {
        return this.getMeta().getSumOfMediumGuessesTimeElapsed();
      }
    
      isUserHasAccess() {
        return this.getMeta().isUserHasAccess();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {boolean}
           */
          isBelongsToStandard() {
            const value = meta.get('belongs_to_standard');
            validate(value, 'belongs_to_standard');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getContentDiscoveryQuestionCount() {
            const value = meta.get('content_discovery_question_count');
            validate(value, 'content_discovery_question_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDifficultCorrectGuesses() {
            const value = meta.get('count_of_difficult_correct_guesses');
            validate(value, 'count_of_difficult_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDifficultGuesses() {
            const value = meta.get('count_of_difficult_guesses');
            validate(value, 'count_of_difficult_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDifficultQuestions() {
            const value = meta.get('count_of_difficult_questions');
            validate(value, 'count_of_difficult_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDifficultQuestionsAnswered() {
            const value = meta.get('count_of_difficult_questions_answered');
            validate(value, 'count_of_difficult_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfEasyCorrectGuesses() {
            const value = meta.get('count_of_easy_correct_guesses');
            validate(value, 'count_of_easy_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfEasyGuesses() {
            const value = meta.get('count_of_easy_guesses');
            validate(value, 'count_of_easy_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfEasyQuestions() {
            const value = meta.get('count_of_easy_questions');
            validate(value, 'count_of_easy_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfEasyQuestionsAnswered() {
            const value = meta.get('count_of_easy_questions_answered');
            validate(value, 'count_of_easy_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfFreeResponseQuestions() {
            const value = meta.get('count_of_free_response_questions');
            validate(value, 'count_of_free_response_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfGuides() {
            const value = meta.get('count_of_guides');
            validate(value, 'count_of_guides');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfMediumCorrectGuesses() {
            const value = meta.get('count_of_medium_correct_guesses');
            validate(value, 'count_of_medium_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfMediumGuesses() {
            const value = meta.get('count_of_medium_guesses');
            validate(value, 'count_of_medium_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfMediumQuestions() {
            const value = meta.get('count_of_medium_questions');
            validate(value, 'count_of_medium_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfMediumQuestionsAnswered() {
            const value = meta.get('count_of_medium_questions_answered');
            validate(value, 'count_of_medium_questions_answered');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPracticeExams() {
            const value = meta.get('count_of_practice_exams');
            validate(value, 'count_of_practice_exams');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfQuestions() {
            const value = meta.get('count_of_questions');
            validate(value, 'count_of_questions');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolAssignments() {
            const value = meta.get('count_of_school_assignments');
            validate(value, 'count_of_school_assignments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolClassrooms() {
            const value = meta.get('count_of_school_classrooms');
            validate(value, 'count_of_school_classrooms');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolCorrectGuesses() {
            const value = meta.get('count_of_school_correct_guesses');
            validate(value, 'count_of_school_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolGuesses() {
            const value = meta.get('count_of_school_guesses');
            validate(value, 'count_of_school_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolIncorrectGuesses() {
            const value = meta.get('count_of_school_incorrect_guesses');
            validate(value, 'count_of_school_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolStudents() {
            const value = meta.get('count_of_school_students');
            validate(value, 'count_of_school_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolTeachers() {
            const value = meta.get('count_of_school_teachers');
            validate(value, 'count_of_school_teachers');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfUsedSeatsBySubject() {
            const value = meta.get('count_of_used_seats_by_subject');
            validate(value, 'count_of_used_seats_by_subject');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          hasAccessViaLicenseAsStudent() {
            const value = meta.get('has_access_via_license_as_student');
            validate(value, 'has_access_via_license_as_student');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          hasAccessViaLicenseAsTeacher() {
            const value = meta.get('has_access_via_license_as_teacher');
            validate(value, 'has_access_via_license_as_teacher');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          hasAccessViaSubscription() {
            const value = meta.get('has_access_via_subscription');
            validate(value, 'has_access_via_subscription');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          hasRestrictedFreePractice() {
            const value = meta.get('has_restricted_free_practice');
            validate(value, 'has_restricted_free_practice');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isIncludedInPersonalizationSettings() {
            const value = meta.get('included_in_personalization_settings');
            validate(value, 'included_in_personalization_settings');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getMatchingGuideLevelsSearchCount() {
            const value = meta.get('matching_guide_levels_search_count');
            validate(value, 'matching_guide_levels_search_count');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string}
           */
          getMetaStudentId() {
            const value = meta.get('meta_student_id');
            validate(value, 'meta_student_id');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isRestrictFreePractice() {
            const value = meta.get('restrict_free_practice');
            validate(value, 'restrict_free_practice');
            return value;
          },
  
          /**
           * @type {float}
           */
          getSearchRelevance() {
            const value = meta.get('search_relevance');
            validate(value, 'search_relevance');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfDifficultGuessesPointsEarned() {
            const value = meta.get('sum_of_difficult_guesses_points_earned');
            validate(value, 'sum_of_difficult_guesses_points_earned');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfDifficultGuessesTimeElapsed() {
            const value = meta.get('sum_of_difficult_guesses_time_elapsed');
            validate(value, 'sum_of_difficult_guesses_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfEasyGuessesPointsEarned() {
            const value = meta.get('sum_of_easy_guesses_points_earned');
            validate(value, 'sum_of_easy_guesses_points_earned');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfEasyGuessesTimeElapsed() {
            const value = meta.get('sum_of_easy_guesses_time_elapsed');
            validate(value, 'sum_of_easy_guesses_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfMediumGuessesPointsEarned() {
            const value = meta.get('sum_of_medium_guesses_points_earned');
            validate(value, 'sum_of_medium_guesses_points_earned');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getSumOfMediumGuessesTimeElapsed() {
            const value = meta.get('sum_of_medium_guesses_time_elapsed');
            validate(value, 'sum_of_medium_guesses_time_elapsed');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {boolean}
           */
          isUserHasAccess() {
            const value = meta.get('user_has_access');
            validate(value, 'user_has_access');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {classroom_v1 (many)}
   */
  getClassrooms() {
    const value = this.getValueForAttribute('classrooms');
    return value;
  }

  // -- Relationship Fields: classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withClassroomRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withClassroomRelationshipGetters(rel));
    } else {
      return this.__withClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_question_set_v1 (many)}
   */
  getAuthoringQuestionSets() {
    const value = this.getValueForAttribute('authoring_question_sets');
    return value;
  }

  // -- Relationship Fields: authoring_question_sets --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_classroom_v1 (many)}
   */
  getStudentClassrooms() {
    const value = this.getValueForAttribute('student_classrooms');
    return value;
  }

  // -- Relationship Fields: student_classrooms --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withStudentClassroomRelationshipGetters(data) {
    return {
      getRestrictFreePractice() {
        return data.get('restrict_free_practice', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getStudentClassroomRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'student_classroom'], new Map());
    if (!id) {
      return data.map((rel) => this.__withStudentClassroomRelationshipGetters(rel));
    } else {
      return this.__withStudentClassroomRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {authoring_label_v1 (many)}
   */
  getAuthoringLabels() {
    const value = this.getValueForAttribute('authoring_labels');
    return value;
  }

  // -- Relationship Fields: authoring_labels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {question_set_v1 (many)}
   */
  getQuestionSets() {
    const value = this.getValueForAttribute('question_sets');
    return value;
  }

  // -- Relationship Fields: question_sets --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_exam_v1 (many)}
   */
  getAuthoringExams() {
    const value = this.getValueForAttribute('authoring_exams');
    return value;
  }

  // -- Relationship Fields: authoring_exams --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_license_subject_group_v1 (many)}
   */
  getSchoolLicenseSubjectGroups() {
    const value = this.getValueForAttribute('school_license_subject_groups');
    return value;
  }

  // -- Relationship Fields: school_license_subject_groups --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subject_faq_v1 (many)}
   */
  getSubjectFaqs() {
    const value = this.getValueForAttribute('subject_faqs');
    return value;
  }

  // -- Relationship Fields: subject_faqs --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {curriculum_area_v1 (one)}
   */
  getCurriculumArea() {
    const value = this.getValueForAttribute('curriculum_area');
    return value;
  }

  // -- Relationship Fields: curriculum_area --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_level_v2 (many)}
   */
  getGuideLevels() {
    const value = this.getValueForAttribute('guide_levels');
    return value;
  }

  // -- Relationship Fields: guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_supplement_v1 (many)}
   */
  getAuthoringSupplements() {
    const value = this.getValueForAttribute('authoring_supplements');
    return value;
  }

  // -- Relationship Fields: authoring_supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {standard_set_v1 (many)}
   */
  getStandardSets() {
    const value = this.getValueForAttribute('standard_sets');
    return value;
  }

  // -- Relationship Fields: standard_sets --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {exam_v1 (many)}
   */
  getExams() {
    const value = this.getValueForAttribute('exams');
    return value;
  }

  // -- Relationship Fields: exams --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {subscription_v1 (many)}
   */
  getSubscriptions() {
    const value = this.getValueForAttribute('subscriptions');
    return value;
  }

  // -- Relationship Fields: subscriptions --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {guide_v1 (many)}
   */
  getGuides() {
    const value = this.getValueForAttribute('guides');
    return value;
  }

  // -- Relationship Fields: guides --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_level_v1 (many)}
   */
  getAuthoringGuideLevels() {
    const value = this.getValueForAttribute('authoring_guide_levels');
    return value;
  }

  // -- Relationship Fields: authoring_guide_levels --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {supplement_v1 (many)}
   */
  getSupplements() {
    const value = this.getValueForAttribute('supplements');
    return value;
  }

  // -- Relationship Fields: supplements --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_license_v1 (many)}
   */
  getSchoolLicenses() {
    const value = this.getValueForAttribute('school_licenses');
    return value;
  }

  // -- Relationship Fields: school_licenses --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {search_standard_v1 (many)}
   */
  getSearchStandards() {
    const value = this.getValueForAttribute('search_standards');
    return value;
  }

  // -- Relationship Fields: search_standards --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {authoring_guide_v1 (many)}
   */
  getAuthoringGuides() {
    const value = this.getValueForAttribute('authoring_guides');
    return value;
  }

  // -- Relationship Fields: authoring_guides --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {label_v1 (many)}
   */
  getLabels() {
    const value = this.getValueForAttribute('labels');
    return value;
  }

  // -- Relationship Fields: labels --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptySubjectModelV2Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'alternate_name': null,
  'banner': null,
  'code': null,
  'description': null,
  'domain': null,
  'featured_as': null,
  'footer_text': null,
  'free_response_questions_published': null,
  'group': null,
  'hidden': null,
  'id': null,
  'inserted_at': null,
  'logo_image_url': null,
  'meta.belongs_to_standard': null,
  'meta.content_discovery_question_count': null,
  'meta.count_of_difficult_correct_guesses': null,
  'meta.count_of_difficult_guesses': null,
  'meta.count_of_difficult_questions': null,
  'meta.count_of_difficult_questions_answered': null,
  'meta.count_of_easy_correct_guesses': null,
  'meta.count_of_easy_guesses': null,
  'meta.count_of_easy_questions': null,
  'meta.count_of_easy_questions_answered': null,
  'meta.count_of_free_response_questions': null,
  'meta.count_of_guides': null,
  'meta.count_of_medium_correct_guesses': null,
  'meta.count_of_medium_guesses': null,
  'meta.count_of_medium_questions': null,
  'meta.count_of_medium_questions_answered': null,
  'meta.count_of_practice_exams': null,
  'meta.count_of_questions': null,
  'meta.count_of_school_assignments': null,
  'meta.count_of_school_classrooms': null,
  'meta.count_of_school_correct_guesses': null,
  'meta.count_of_school_guesses': null,
  'meta.count_of_school_incorrect_guesses': null,
  'meta.count_of_school_students': null,
  'meta.count_of_school_teachers': null,
  'meta.count_of_used_seats_by_subject': null,
  'meta.has_access_via_license_as_student': null,
  'meta.has_access_via_license_as_teacher': null,
  'meta.has_access_via_subscription': null,
  'meta.has_restricted_free_practice': null,
  'meta.included_in_personalization_settings': null,
  'meta.matching_guide_levels_search_count': null,
  'meta.restrict_free_practice': null,
  'meta.search_relevance': null,
  'meta.sum_of_difficult_guesses_points_earned': null,
  'meta.sum_of_difficult_guesses_time_elapsed': null,
  'meta.sum_of_easy_guesses_points_earned': null,
  'meta.sum_of_easy_guesses_time_elapsed': null,
  'meta.sum_of_medium_guesses_points_earned': null,
  'meta.sum_of_medium_guesses_time_elapsed': null,
  'meta.user_has_access': null,
  'name': null,
  'search_by_name': null,
  'should_show_label_table': null,
  'updated_at': null,
  'url_slug': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'alternate_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'authoring_exams_v1': new Set(['associated']),
  'authoring_guide_levels_v1': new Set(['associated']),
  'authoring_guides_v1': new Set(['associated']),
  'authoring_labels_v1': new Set(['associated']),
  'authoring_question_sets_v1': new Set(['associated']),
  'authoring_supplements_v1': new Set(['associated']),
  'banner': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'classrooms_v1': new Set(['associated']),
  'code': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'curriculum_area_v1': new Set(['associated']),
  'description': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'domain': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'exams_v1': new Set(['associated']),
  'featured_as': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'footer_text': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'free_response_questions_published': new Set(['exact','not','null']),
  'group': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'guide_levels_v1': new Set(['associated']),
  'guide_levels_v2': new Set(['associated']),
  'guides_v1': new Set(['associated']),
  'hidden': new Set(['exact','not','null']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'labels_v1': new Set(['associated']),
  'logo_image_url': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.belongs_to_standard': new Set(['exact','not','null']),
  'meta.content_discovery_question_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_difficult_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_difficult_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_difficult_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_difficult_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_easy_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_easy_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_easy_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_easy_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_free_response_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_guides': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_medium_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_medium_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_medium_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_medium_questions_answered': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_practice_exams': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_questions': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_assignments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_classrooms': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_teachers': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_used_seats_by_subject': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.has_access_via_license_as_student': new Set(['exact','not','null']),
  'meta.has_access_via_license_as_teacher': new Set(['exact','not','null']),
  'meta.has_access_via_subscription': new Set(['exact','not','null']),
  'meta.has_restricted_free_practice': new Set(['exact','not','null']),
  'meta.included_in_personalization_settings': new Set(['exact','not','null']),
  'meta.matching_guide_levels_search_count': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.restrict_free_practice': new Set(['exact','not','null']),
  'meta.search_relevance': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_difficult_guesses_points_earned': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_difficult_guesses_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_easy_guesses_points_earned': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_easy_guesses_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_medium_guesses_points_earned': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.sum_of_medium_guesses_time_elapsed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.user_has_access': new Set(['exact','not','null']),
  'name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'question_sets_v1': new Set(['associated']),
  'school_license_subject_groups_v1': new Set(['associated']),
  'school_licenses_v1': new Set(['associated']),
  'search_standards_v1': new Set(['associated']),
  'should_show_label_table': new Set(['exact','not','null']),
  'standard_sets_v1': new Set(['associated']),
  'student_classrooms_v1': new Set(['associated']),
  'subject_faqs_v1': new Set(['associated']),
  'subscriptions_prices': new Set(['null']),
  'subscriptions_v1': new Set(['associated']),
  'supplements_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'url_slug': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'classroom_id': 'meta.context.classroom.id',
    'classroom_statuses': 'meta.context.classroom.statuses',
    'content_discovery_filtering': 'meta.context.content_discovery_filtering',
    'content_discovery_filters_guide_levels': 'meta.context.content_discovery_filters.guide_levels',
    'content_discovery_filters_hide_assessment_questions': 'meta.context.content_discovery_filters.hide_assessment_questions',
    'content_discovery_filters_hide_previously_assigned': 'meta.context.content_discovery_filters.hide_previously_assigned',
    'content_discovery_filters_question_difficulties': 'meta.context.content_discovery_filters.question_difficulties',
    'content_discovery_filters_question_types': 'meta.context.content_discovery_filters.question_types',
    'content_discovery_filters_standard_sets': 'meta.context.content_discovery_filters.standard_sets',
    'content_discovery_filters_standards': 'meta.context.content_discovery_filters.standards',
    'csv_headers': 'meta.context.csv_headers',
    'end_date': 'meta.context.end_date',
    'matching_guide_levels_search_fields': 'meta.context.matching_guide_levels.search_fields',
    'matching_guide_levels_search_term': 'meta.context.matching_guide_levels.search_term',
    'school_id': 'meta.context.school.id',
    'school_administrator_dashboard': 'meta.context.school_administrator.dashboard',
    'school_license_id': 'meta.context.school_license.id',
    'search_fields': 'meta.context.search.fields',
    'search_term': 'meta.context.search.term',
    'standard_id': 'meta.context.standard.id',
    'start_date': 'meta.context.start_date',
    'student_id': 'meta.context.student.id',
    'subject_breakdown': 'meta.context.subject.breakdown',
    'subject_counts': 'meta.context.subject.counts',
    'user_id': 'meta.context.user.id',
    'personalization_settings': 'meta.context.user.personalization_settings',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Subject = SubjectModelV2;
