/* eslint-disable */


// Programatically generated on Thu Apr 10 2025 21:12:51 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'teachers_v1',
    'attributes': {
      'username': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'salutation': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'last_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'first_name': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'email': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'issued_classroom_invitations_v1': {
        'type': 'one-to-many'
      },
      'templates_v1': {
        'type': 'one-to-many'
      },
      'school_personnel_v1': {
        'type': 'one-to-many'
      },
      'school_v1': {
        'type': 'one-to-one'
      },
      'school_v2': {
        'type': 'one-to-one'
      },
      'school_v3': {
        'type': 'one-to-one'
      },
      'school_v4': {
        'type': 'one-to-one'
      },
      'school_v5': {
        'type': 'one-to-one'
      },
      'user_v1': {
        'type': 'one-to-one'
      },
      'user_v2': {
        'type': 'one-to-one'
      },
      'draft_assignments_v1': {
        'type': 'one-to-many'
      },
      'assignments_v1': {
        'type': 'one-to-many'
      },
      'standard_set_creators_v1': {
        'type': 'one-to-many'
      },
      'assignments_v2': {
        'type': 'one-to-many'
      },
      'assignments_v3': {
        'type': 'one-to-many'
      },
      'student_classrooms_v1': {
        'type': 'one-to-many'
      },
      'student_assignments_v1': {
        'type': 'one-to-many'
      },
      'curriculum_areas_v1': {
        'type': 'one-to-many'
      },
      'classrooms_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'classrooms': Empty,
    'curriculum_areas': Empty,
    'student_assignments': Empty,
    'student_classrooms': Empty,
    'standard_set_creators': Empty,
    'assignments': Empty,
    'draft_assignments': Empty,
    'user': Empty,
    'last_name': Empty,
    'school': Empty,
    'school_personnel': Empty,
    'username': Empty,
    'updated_at': Empty,
    'salutation': Empty,
    'templates': Empty,
    'inserted_at': Empty,
    'issued_classroom_invitations': Empty,
    'first_name': Empty,
    'id': Empty,
    'email': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'school_subject_breakdown': Empty,
    'populated_metadata_fields': Empty,
    'count_of_active_classes': Empty,
    'random_number': Empty,
    'intercom_stats': Empty,
    'count_of_pending_student_invites': Empty,
    'two_distinct_guess_weeks': Empty,
    'school_subject_names': Empty,
    'has_active_school_license': Empty,
    'count_of_school_students': Empty,
    'future_school_licenses_info': Empty,
    'folders_created_this_year': Empty,
    'classes_created_this_year': Empty,
    'class_subjects': Empty,
    'assignments_created_this_year': Empty,
    'is_approved_teacher': Empty,
    'guess_subjects': Empty,
    'school_licenses_info': Empty,
    'count_of_pending_teachers': Empty,
    'count_of_school_guesses': Empty,
    'count_of_school_classrooms': Empty,
    'count_of_school_assignments': Empty,
    'count_of_unaccepted_invitations': Empty,
    'is_approved_school_administrator': Empty,
    'count_of_assignments_created': Empty,
    'count_of_school_incorrect_guesses': Empty,
    'license_type': Empty,
    'is_licensed': Empty,
    'count_of_active_students': Empty,
    'assessments_assigned_this_year': Empty,
    'folders_created_all_time': Empty,
    'thirty_plus_guesses': Empty,
    'count_of_school_correct_guesses': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'last_name': '',
    'username': '',
    'salutation': '',
    'first_name': '',
    'id': '',
    'email': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'classrooms': new List(),
    'curriculum_areas': new List(),
    'student_assignments': new List(),
    'student_classrooms': new List(),
    'standard_set_creators': new List(),
    'assignments': new List(),
    'draft_assignments': new List(),
    'school_personnel': new List(),
    'templates': new List(),
    'issued_classroom_invitations': new List(),
    'meta': new Map(),
    'user': new Map(),
    'school': new Map(),
    'relationshipMeta': new Map()
  }
);

export class TeacherModelV1 extends GenericModel(ModelRecord, ModelConfig, 'TeacherModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyTeacherModelV1Model = _emptyTeacherModelV1Model ? _emptyTeacherModelV1Model : new TeacherModelV1(DefaultModel);
    return _emptyTeacherModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'TeacherModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'teacher_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getEmail() {
    const value = this.getValueForAttribute('email');
    return value;
  }

  setEmail(value) {
    return this.setField('email', value);
  }

  /**
   * @type {string}
   */
  getFirstName() {
    const value = this.getValueForAttribute('first_name');
    return value;
  }

  setFirstName(value) {
    return this.setField('first_name', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getLastName() {
    const value = this.getValueForAttribute('last_name');
    return value;
  }

  setLastName(value) {
    return this.setField('last_name', value);
  }

  /**
   * @type {string}
   */
  getSalutation() {
    const value = this.getValueForAttribute('salutation');
    return value;
  }

  setSalutation(value) {
    return this.setField('salutation', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {string}
   */
  getUsername() {
    const value = this.getValueForAttribute('username');
    return value;
  }

  setUsername(value) {
    return this.setField('username', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAssessmentsAssignedThisYear() {
        return this.getMeta().getAssessmentsAssignedThisYear();
      }
    
      getAssignmentsCreatedThisYear() {
        return this.getMeta().getAssignmentsCreatedThisYear();
      }
    
      getClassSubjects() {
        return this.getMeta().getClassSubjects();
      }
    
      getClassesCreatedThisYear() {
        return this.getMeta().getClassesCreatedThisYear();
      }
    
      getCountOfActiveClasses() {
        return this.getMeta().getCountOfActiveClasses();
      }
    
      getCountOfActiveStudents() {
        return this.getMeta().getCountOfActiveStudents();
      }
    
      getCountOfAssignmentsCreated() {
        return this.getMeta().getCountOfAssignmentsCreated();
      }
    
      getCountOfPendingStudentInvites() {
        return this.getMeta().getCountOfPendingStudentInvites();
      }
    
      getCountOfPendingTeachers() {
        return this.getMeta().getCountOfPendingTeachers();
      }
    
      getCountOfSchoolAssignments() {
        return this.getMeta().getCountOfSchoolAssignments();
      }
    
      getCountOfSchoolClassrooms() {
        return this.getMeta().getCountOfSchoolClassrooms();
      }
    
      getCountOfSchoolCorrectGuesses() {
        return this.getMeta().getCountOfSchoolCorrectGuesses();
      }
    
      getCountOfSchoolGuesses() {
        return this.getMeta().getCountOfSchoolGuesses();
      }
    
      getCountOfSchoolIncorrectGuesses() {
        return this.getMeta().getCountOfSchoolIncorrectGuesses();
      }
    
      getCountOfSchoolStudents() {
        return this.getMeta().getCountOfSchoolStudents();
      }
    
      getCountOfUnacceptedInvitations() {
        return this.getMeta().getCountOfUnacceptedInvitations();
      }
    
      getFoldersCreatedAllTime() {
        return this.getMeta().getFoldersCreatedAllTime();
      }
    
      getFoldersCreatedThisYear() {
        return this.getMeta().getFoldersCreatedThisYear();
      }
    
      getFutureSchoolLicensesInfo() {
        return this.getMeta().getFutureSchoolLicensesInfo();
      }
    
      getGuessSubjects() {
        return this.getMeta().getGuessSubjects();
      }
    
      hasActiveSchoolLicense() {
        return this.getMeta().hasActiveSchoolLicense();
      }
    
      getIntercomStats() {
        return this.getMeta().getIntercomStats();
      }
    
      isApprovedSchoolAdministrator() {
        return this.getMeta().isApprovedSchoolAdministrator();
      }
    
      isApprovedTeacher() {
        return this.getMeta().isApprovedTeacher();
      }
    
      isLicensed() {
        return this.getMeta().isLicensed();
      }
    
      getLicenseType() {
        return this.getMeta().getLicenseType();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
      getRandomNumber() {
        return this.getMeta().getRandomNumber();
      }
    
      getSchoolLicensesInfo() {
        return this.getMeta().getSchoolLicensesInfo();
      }
    
      getSchoolSubjectBreakdown() {
        return this.getMeta().getSchoolSubjectBreakdown();
      }
    
      getSchoolSubjectNames() {
        return this.getMeta().getSchoolSubjectNames();
      }
    
      getThirtyPlusGuesses() {
        return this.getMeta().getThirtyPlusGuesses();
      }
    
      getTwoDistinctGuessWeeks() {
        return this.getMeta().getTwoDistinctGuessWeeks();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getAssessmentsAssignedThisYear() {
            const value = meta.get('assessments_assigned_this_year');
            validate(value, 'assessments_assigned_this_year');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAssignmentsCreatedThisYear() {
            const value = meta.get('assignments_created_this_year');
            validate(value, 'assignments_created_this_year');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string}
           */
          getClassSubjects() {
            const value = meta.get('class_subjects');
            validate(value, 'class_subjects');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getClassesCreatedThisYear() {
            const value = meta.get('classes_created_this_year');
            validate(value, 'classes_created_this_year');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfActiveClasses() {
            const value = meta.get('count_of_active_classes');
            validate(value, 'count_of_active_classes');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfActiveStudents() {
            const value = meta.get('count_of_active_students');
            validate(value, 'count_of_active_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfAssignmentsCreated() {
            const value = meta.get('count_of_assignments_created');
            validate(value, 'count_of_assignments_created');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPendingStudentInvites() {
            const value = meta.get('count_of_pending_student_invites');
            validate(value, 'count_of_pending_student_invites');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfPendingTeachers() {
            const value = meta.get('count_of_pending_teachers');
            validate(value, 'count_of_pending_teachers');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolAssignments() {
            const value = meta.get('count_of_school_assignments');
            validate(value, 'count_of_school_assignments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolClassrooms() {
            const value = meta.get('count_of_school_classrooms');
            validate(value, 'count_of_school_classrooms');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolCorrectGuesses() {
            const value = meta.get('count_of_school_correct_guesses');
            validate(value, 'count_of_school_correct_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolGuesses() {
            const value = meta.get('count_of_school_guesses');
            validate(value, 'count_of_school_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolIncorrectGuesses() {
            const value = meta.get('count_of_school_incorrect_guesses');
            validate(value, 'count_of_school_incorrect_guesses');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSchoolStudents() {
            const value = meta.get('count_of_school_students');
            validate(value, 'count_of_school_students');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfUnacceptedInvitations() {
            const value = meta.get('count_of_unaccepted_invitations');
            validate(value, 'count_of_unaccepted_invitations');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getFoldersCreatedAllTime() {
            const value = meta.get('folders_created_all_time');
            validate(value, 'folders_created_all_time');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getFoldersCreatedThisYear() {
            const value = meta.get('folders_created_this_year');
            validate(value, 'folders_created_this_year');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {map[]}
           */
          getFutureSchoolLicensesInfo() {
            const value = meta.get('future_school_licenses_info');
            validate(value, 'future_school_licenses_info');
            return value;
          },
  
          /**
           * @type {map}
           */
          getGuessSubjects() {
            const value = meta.get('guess_subjects');
            validate(value, 'guess_subjects');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          hasActiveSchoolLicense() {
            const value = meta.get('has_active_school_license');
            validate(value, 'has_active_school_license');
            return value;
          },
  
          /**
           * @type {map}
           */
          getIntercomStats() {
            const value = meta.get('intercom_stats');
            validate(value, 'intercom_stats');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isApprovedSchoolAdministrator() {
            const value = meta.get('is_approved_school_administrator');
            validate(value, 'is_approved_school_administrator');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isApprovedTeacher() {
            const value = meta.get('is_approved_teacher');
            validate(value, 'is_approved_teacher');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isLicensed() {
            const value = meta.get('is_licensed');
            validate(value, 'is_licensed');
            return value;
          },
  
          /**
           * @type {string}
           */
          getLicenseType() {
            const value = meta.get('license_type');
            validate(value, 'license_type');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getRandomNumber() {
            const value = meta.get('random_number');
            validate(value, 'random_number');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {map[]}
           */
          getSchoolLicensesInfo() {
            const value = meta.get('school_licenses_info');
            validate(value, 'school_licenses_info');
            return value;
          },
  
          /**
           * @type {map}
           */
          getSchoolSubjectBreakdown() {
            const value = meta.get('school_subject_breakdown');
            validate(value, 'school_subject_breakdown');
            return value;
          },
  
          /**
           * @type {string}
           */
          getSchoolSubjectNames() {
            const value = meta.get('school_subject_names');
            validate(value, 'school_subject_names');
            return value;
          },
  
          /**
           * @type {float}
           */
          getThirtyPlusGuesses() {
            const value = meta.get('thirty_plus_guesses');
            validate(value, 'thirty_plus_guesses');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
          /**
           * @type {float}
           */
          getTwoDistinctGuessWeeks() {
            const value = meta.get('two_distinct_guess_weeks');
            validate(value, 'two_distinct_guess_weeks');
            
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {classroom_v1 (many)}
   */
  getClassrooms() {
    const value = this.getValueForAttribute('classrooms');
    return value;
  }

  // -- Relationship Fields: classrooms --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {curriculum_area_v1 (many)}
   */
  getCurriculumAreas() {
    const value = this.getValueForAttribute('curriculum_areas');
    return value;
  }

  // -- Relationship Fields: curriculum_areas --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_assignment_v1 (many)}
   */
  getStudentAssignments() {
    const value = this.getValueForAttribute('student_assignments');
    return value;
  }

  // -- Relationship Fields: student_assignments --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {student_classroom_v1 (many)}
   */
  getStudentClassrooms() {
    const value = this.getValueForAttribute('student_classrooms');
    return value;
  }

  // -- Relationship Fields: student_classrooms --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {standard_set_creator_v1 (many)}
   */
  getStandardSetCreators() {
    const value = this.getValueForAttribute('standard_set_creators');
    return value;
  }

  // -- Relationship Fields: standard_set_creators --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {assignment_v3 (many)}
   */
  getAssignments() {
    const value = this.getValueForAttribute('assignments');
    return value;
  }

  // -- Relationship Fields: assignments --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {draft_assignment_v1 (many)}
   */
  getDraftAssignments() {
    const value = this.getValueForAttribute('draft_assignments');
    return value;
  }

  // -- Relationship Fields: draft_assignments --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {user_v2 (one)}
   */
  getUser() {
    const value = this.getValueForAttribute('user');
    return value;
  }

  // -- Relationship Fields: user --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_v5 (one)}
   */
  getSchool() {
    const value = this.getValueForAttribute('school');
    return value;
  }

  // -- Relationship Fields: school --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {school_personnel_v1 (many)}
   */
  getSchoolPersonnel() {
    const value = this.getValueForAttribute('school_personnel');
    return value;
  }

  // -- Relationship Fields: school_personnel --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {template_v1 (many)}
   */
  getTemplates() {
    const value = this.getValueForAttribute('templates');
    return value;
  }

  // -- Relationship Fields: templates --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {classroom_invitation_v1 (many)}
   */
  getIssuedClassroomInvitations() {
    const value = this.getValueForAttribute('issued_classroom_invitations');
    return value;
  }

  // -- Relationship Fields: issued_classroom_invitations --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyTeacherModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'email': null,
  'first_name': null,
  'id': null,
  'inserted_at': null,
  'last_name': null,
  'meta.assessments_assigned_this_year': null,
  'meta.assignments_created_this_year': null,
  'meta.class_subjects': null,
  'meta.classes_created_this_year': null,
  'meta.count_of_active_classes': null,
  'meta.count_of_active_students': null,
  'meta.count_of_assignments_created': null,
  'meta.count_of_pending_student_invites': null,
  'meta.count_of_pending_teachers': null,
  'meta.count_of_school_assignments': null,
  'meta.count_of_school_classrooms': null,
  'meta.count_of_school_correct_guesses': null,
  'meta.count_of_school_guesses': null,
  'meta.count_of_school_incorrect_guesses': null,
  'meta.count_of_school_students': null,
  'meta.count_of_unaccepted_invitations': null,
  'meta.folders_created_all_time': null,
  'meta.folders_created_this_year': null,
  'meta.has_active_school_license': null,
  'meta.is_approved_school_administrator': null,
  'meta.is_approved_teacher': null,
  'meta.is_licensed': null,
  'meta.license_type': null,
  'meta.random_number': null,
  'meta.school_subject_names': null,
  'meta.thirty_plus_guesses': null,
  'meta.two_distinct_guess_weeks': null,
  'salutation': null,
  'updated_at': null,
  'username': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'assignments_v1': new Set(['associated']),
  'assignments_v2': new Set(['associated']),
  'assignments_v3': new Set(['associated']),
  'classrooms_v1': new Set(['associated']),
  'curriculum_areas_v1': new Set(['associated']),
  'draft_assignments_v1': new Set(['associated']),
  'email': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'first_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'issued_classroom_invitations_v1': new Set(['associated']),
  'last_name': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.assessments_assigned_this_year': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.assignments_created_this_year': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.class_subjects': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.classes_created_this_year': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_active_classes': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_active_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_assignments_created': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_pending_student_invites': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_pending_teachers': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_assignments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_classrooms': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_correct_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_incorrect_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_school_students': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_unaccepted_invitations': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.folders_created_all_time': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.folders_created_this_year': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.has_active_school_license': new Set(['exact','not','null']),
  'meta.is_approved_school_administrator': new Set(['exact','not','null']),
  'meta.is_approved_teacher': new Set(['exact','not','null']),
  'meta.is_licensed': new Set(['exact','not','null']),
  'meta.license_type': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'meta.random_number': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.school_subject_names': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.thirty_plus_guesses': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.two_distinct_guess_weeks': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'salutation': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'school_personnel_v1': new Set(['associated']),
  'school_v1': new Set(['associated']),
  'school_v2': new Set(['associated']),
  'school_v3': new Set(['associated']),
  'school_v4': new Set(['associated']),
  'school_v5': new Set(['associated']),
  'standard_set_creators_v1': new Set(['associated']),
  'student_assignments_v1': new Set(['associated']),
  'student_classrooms_v1': new Set(['associated']),
  'templates_v1': new Set(['associated']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'user_v1': new Set(['associated']),
  'user_v2': new Set(['associated']),
  'username': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'classroom_statuses': 'meta.context.classroom.statuses',
    'csv_headers': 'meta.context.csv_headers',
    'end_date': 'meta.context.end_date',
    'school_id': 'meta.context.school.id',
    'school_administrator_dashboard': 'meta.context.school_administrator.dashboard',
    'start_date': 'meta.context.start_date',
    'subject_breakdown': 'meta.context.subject.breakdown',
    'future_license_info': 'meta.context.teacher.future_license_info',
    'teacher_id': 'meta.context.teacher.id',
    'intercom_stats': 'meta.context.teacher.intercom_stats',
    'license_info': 'meta.context.teacher.license_info',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const Teacher = TeacherModelV1;
