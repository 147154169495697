/* eslint-disable */


// Programatically generated on Thu Apr 10 2025 21:12:51 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'test_comments_v1',
    'attributes': {
      'title': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'content': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'summable': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'read_only_test_post_v1': {
        'type': 'one-to-one'
      },
      'test_categories_v1': {
        'type': 'one-to-many'
      },
      'test_post_v1': {
        'type': 'one-to-one'
      },
      'test_thread_v1': {
        'type': 'one-to-one'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'read_only_test_post': Empty,
    'test_categories': Empty,
    'test_post': Empty,
    'updated_at': Empty,
    'test_thread': Empty,
    'title': Empty,
    'content': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'summable': Empty,
    'relationshipMeta': Empty,
    'meta': new Map({
    'content_preview': Empty,
    'populated_metadata_fields': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'title': '',
    'content': '',
    'id': '',
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'test_categories': new List(),
    'read_only_test_post': new Map(),
    'meta': new Map(),
    'test_post': new Map(),
    'test_thread': new Map(),
    'summable': 0,
    'relationshipMeta': new Map()
  }
);

export class TestCommentModelV1 extends GenericModel(ModelRecord, ModelConfig, 'TestCommentModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyTestCommentModelV1Model = _emptyTestCommentModelV1Model ? _emptyTestCommentModelV1Model : new TestCommentModelV1(DefaultModel);
    return _emptyTestCommentModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'TestCommentModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'test_comment_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {string}
   */
  getContent() {
    const value = this.getValueForAttribute('content');
    return value;
  }

  setContent(value) {
    return this.setField('content', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {integer}
   */
  getSummable() {
    const value = this.getValueForAttribute('summable');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setSummable(value) {
    return this.setField('summable', value);
  }

  /**
   * @type {string}
   */
  getTitle() {
    const value = this.getValueForAttribute('title');
    return value;
  }

  setTitle(value) {
    return this.setField('title', value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getContentPreview() {
        return this.getMeta().getContentPreview();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {string}
           */
          getContentPreview() {
            const value = meta.get('content_preview');
            validate(value, 'content_preview');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {read_only_test_post_v1 (one)}
   */
  getReadOnlyTestPost() {
    const value = this.getValueForAttribute('read_only_test_post');
    return value;
  }

  // -- Relationship Fields: read_only_test_post --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {test_category_v1 (many)}
   */
  getTestCategories() {
    const value = this.getValueForAttribute('test_categories');
    return value;
  }

  // -- Relationship Fields: test_categories --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withTestCategoryRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getTestCategoryRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'test_category'], new Map());
    if (!id) {
      return data.map((rel) => this.__withTestCategoryRelationshipGetters(rel));
    } else {
      return this.__withTestCategoryRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {test_post_v1 (one)}
   */
  getTestPost() {
    const value = this.getValueForAttribute('test_post');
    return value;
  }

  // -- Relationship Fields: test_post --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {test_thread_v1 (one)}
   */
  getTestThread() {
    const value = this.getValueForAttribute('test_thread');
    return value;
  }

  // -- Relationship Fields: test_thread --
  // no 'fields' found, skipped generating accessor(s)
  
}

// -- Empty Model Instance --
let _emptyTestCommentModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'content': null,
  'id': null,
  'inserted_at': null,
  'meta.content_preview': null,
  'summable': null,
  'title': null,
  'updated_at': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'content': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.content_preview': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'read_only_test_post_v1': new Set(['associated']),
  'summable': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'test_categories_v1': new Set(['associated']),
  'test_post_v1': new Set(['associated']),
  'test_thread_v1': new Set(['associated']),
  'title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
  },
  required: {
  }
});

// -- Legacy Name Export --
export const TestComment = TestCommentModelV1;
