import React from 'react';

const questionStemRegex = /^\[question-stem\]([\s\S]*?)\[\/question-stem\]/;

// renders [question-stem]...[/question-stem] as-is in a div
// for when we're not extracting to the right side
export const questionStemRule = {
  order: 0,
  match: (source) => questionStemRegex.exec(source),
  parse: (capture, parse) => ({
    content: parse(`\n${capture[1]}\n`)
  }),
  react: (node, output) => {
    return <div>{output(node.content)}</div>;
  }
};
