/* eslint-disable */


// Programatically generated on Thu Apr 10 2025 21:12:51 GMT+0000
import {fromJS, List, Map, Record, Set} from 'immutable';
import {GenericModel} from 'resources/Generic.model';
import {validate, Empty} from '@albert-io/json-api-framework/models/helpers';
import moment from 'moment';

const ModelConfig = fromJS(
  {
    'endpoint': 'test_posts_v1',
    'attributes': {
      'published_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'content_tsvectors_simple': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'authored_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'migrated_from_ids': {
        'type': 'binary_id[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'random_seed': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'upvotes': {
        'type': 'integer',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'metadata': {
        'type': 'map',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'status': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'subject_matter': {
        'type': 'ltree',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'reviewer_id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'updated_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'tags': {
        'type': 'string[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'content': {
        'type': 'string',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'sponsored': {
        'type': 'boolean',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'inserted_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': true,
        'isWriteOnly': false
      },
      'id': {
        'type': 'binary_id',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'content_tsvectors_english': {
        'type': 'tsvector',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'unpublished_at': {
        'type': 'datetime',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'rating': {
        'type': 'float',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      },
      'migrated_from_metadata': {
        'type': 'map[]',
        'isRequired': false,
        'isReadOnly': false,
        'isWriteOnly': false
      }
    },
    'relationships': {
      'test_categories_v1': {
        'type': 'one-to-many'
      },
      'test_comments_v1': {
        'type': 'one-to-many'
      },
      'test_editors_v1': {
        'type': 'one-to-many'
      },
      'test_self_unlinked_comments_v1': {
        'type': 'one-to-many'
      }
    }
  }
);

const ModelRecord = Record(
  {
    'test_comments': Empty,
    'published_at': Empty,
    'content_tsvectors_simple': Empty,
    'authored_at': Empty,
    'migrated_from_ids': Empty,
    'test_categories': Empty,
    'random_seed': Empty,
    'upvotes': Empty,
    'metadata': Empty,
    'status': Empty,
    'subject_matter': Empty,
    'reviewer_id': Empty,
    'updated_at': Empty,
    'tags': Empty,
    'content': Empty,
    'sponsored': Empty,
    'test_editors': Empty,
    'inserted_at': Empty,
    'id': Empty,
    'test_self_unlinked_comments': Empty,
    'content_tsvectors_english': Empty,
    'relationshipMeta': Empty,
    'unpublished_at': Empty,
    'rating': Empty,
    'migrated_from_metadata': Empty,
    'meta': new Map({
    'auto_sum_of_comment_summable': Empty,
    'populated_metadata_fields': Empty,
    'auto_count_of_student_content_comments': Empty,
    'count_of_distinct_comment_titles': Empty,
    'comment_dash_titles': Empty,
    'first_comment_title': Empty,
    'count_of_distinct_student_comment_titles': Empty,
    'auto_count_of_contextualized_comments': Empty,
    'populated_by_any_of': Empty,
    'not_sponsored': Empty,
    'last_comment_title': Empty,
    'count_of_samesies_comments': Empty,
    'populated_by_all_of': Empty,
    'auto_count_of_awesome_comments': Empty,
    'count_of_comments_with_awesome_and_horrible_categories': Empty,
    'comment_title_complex_order': Empty,
    'count_of_future_comments': Empty,
    'auto_count_of_past_comments': Empty,
    'has_comments': Empty,
    'echo_student_id': Empty,
    'comment_titles': Empty,
    'auto_count_of_comments': Empty,
    'count_of_distinct_comment_titles_inner': Empty,
    'count_of_comments_with_awesome_categories': Empty,
    'distinct_comment_titles': Empty
  })
  }
);

const DefaultModel = fromJS(
  {
    'status': '',
    'subject_matter': '',
    'reviewer_id': '',
    'content': '',
    'id': '',
    'published_at': new Date(),
    'authored_at': new Date(),
    'updated_at': new Date(),
    'inserted_at': new Date(),
    'unpublished_at': new Date(),
    'test_comments': new List(),
    'migrated_from_ids': new List(),
    'test_categories': new List(),
    'meta': new Map(),
    'content_tsvectors_simple': new Map(),
    'random_seed': 0,
    'upvotes': 0,
    'tags': new List(),
    'test_editors': new List(),
    'test_self_unlinked_comments': new List(),
    'metadata': new Map(),
    'sponsored': false,
    'migrated_from_metadata': new List(),
    'content_tsvectors_english': new Map(),
    'relationshipMeta': new Map(),
    'rating': 0
  }
);

export class TestPostModelV1 extends GenericModel(ModelRecord, ModelConfig, 'TestPostModelV1') {
  // -- Query Rules --
  /**
   * @returns {boolean}
   */
  static allowsInclude(include) {
    // AllowedIncludes is currently disabled.
    return true; // AllowedIncludes.has(include);
  }

  /**
   * @returns {boolean}
   */
  static allowsFilter(filterField, filterType) {
    return filterType ?
      FilterRules.get(filterField).has(filterType) :
      FilterRules.has(filterField);
  }

  /**
   * @returns {boolean}
   */
  static allowsSort(sort) {
    return sort.split(',').filter((s) => AllowedSorts.has(s)).length > 0;
  }

  /**
   * @returns {Array}
   */
  static getAllowedSorts() {
    return AllowedSorts.keySeq().toJS();
  }

  static getMetaContextQuery(key) {
    if (!MetadataContextRules.hasIn(['optional', key]) && !MetadataContextRules.hasIn(['required', key])) {
      console.error('Rule for meta context key ' + key + ' does not exist');
    }

    return MetadataContextRules.getIn(['optional', key]) || MetadataContextRules.getIn(['required', key]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getRequiredMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values()]);
  }

  /**
   * @returns {Immutable.Set}
   */
  static getAllMetaContextRules() {
    return new Set([...MetadataContextRules.get('required').values(), ...MetadataContextRules.get('optional').values()]);
  }

  static getDefaultModel() {
    _emptyTestPostModelV1Model = _emptyTestPostModelV1Model ? _emptyTestPostModelV1Model : new TestPostModelV1(DefaultModel);
    return _emptyTestPostModelV1Model;
  }

  static supportsMetaContext() {
    return MetadataContextRules !== null;
  }

  /**
   * @returns {string}
   */
  static getModelName() {
    return 'TestPostModelV1';
  }

  /**
   * @returns {boolean}
   */
  static _isFullSpec() {
    return true;
  }

  constructor() {
    super(...arguments);
    this.__meta = null;
  }

  /**
   * @returns {string}
   */
  getJsonApiType() {
    return 'test_post_v1';
  }

  /**
   * @params {string} - An attribute name
   * @params {*} [def] - Optional default value override for the attribute
   */
  getValueForAttribute(attribute, def) {
    let value = this.get(attribute);
    validate(value, attribute);
    if (value === Empty) {
      value = def !== undefined ? def : DefaultModel.get(attribute);
    }
    return value;
  }

  // -- Primary Attributes --
  
  /**
   * @type {datetime}
   */
  getAuthoredAt() {
    const value = this.getValueForAttribute('authored_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setAuthoredAt(value) {
    const momentValue = moment(value);
    return this.setField('authored_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {string}
   */
  getContent() {
    const value = this.getValueForAttribute('content');
    return value;
  }

  setContent(value) {
    return this.setField('content', value);
  }

  /**
   * @type {tsvector}
   */
  getContentTsvectorsEnglish() {
    const value = this.getValueForAttribute('content_tsvectors_english');
    return value;
  }

  setContentTsvectorsEnglish(value) {
    return this.setField('content_tsvectors_english', value);
  }

  /**
   * @type {tsvector}
   */
  getContentTsvectorsSimple() {
    const value = this.getValueForAttribute('content_tsvectors_simple');
    return value;
  }

  setContentTsvectorsSimple(value) {
    return this.setField('content_tsvectors_simple', value);
  }

  /**
   * @type {binary_id}
   */
  getId() {
    const value = this.getValueForAttribute('id');
    return value;
  }

  setId(value) {
    return this.setField('id', value);
  }

  /**
   * @type {datetime}
   */
  getInsertedAt() {
    const value = this.getValueForAttribute('inserted_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {map}
   */
  getMetadata() {
    const value = this.getValueForAttribute('metadata');
    return value;
  }

  setMetadata(value) {
    return this.setField('metadata', value);
  }

  /**
   * @type {binary_id[]}
   */
  getMigratedFromIds() {
    const value = this.getValueForAttribute('migrated_from_ids');
    return value;
  }

  setMigratedFromIds(value) {
    return this.setField('migrated_from_ids', value);
  }

  /**
   * @type {map[]}
   */
  getMigratedFromMetadata() {
    const value = this.getValueForAttribute('migrated_from_metadata');
    return value;
  }

  setMigratedFromMetadata(value) {
    return this.setField('migrated_from_metadata', value);
  }

  /**
   * @type {datetime}
   */
  getPublishedAt() {
    const value = this.getValueForAttribute('published_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setPublishedAt(value) {
    const momentValue = moment(value);
    return this.setField('published_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {integer}
   */
  getRandomSeed() {
    const value = this.getValueForAttribute('random_seed');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setRandomSeed(value) {
    return this.setField('random_seed', value);
  }

  /**
   * @type {float}
   */
  getRating() {
    const value = this.getValueForAttribute('rating');
    
      const float = parseFloat(value, 10);
      return isNaN(float) ? value : float;
    
  }

  setRating(value) {
    return this.setField('rating', value);
  }

  /**
   * @type {binary_id}
   */
  getReviewerId() {
    const value = this.getValueForAttribute('reviewer_id');
    return value;
  }

  setReviewerId(value) {
    return this.setField('reviewer_id', value);
  }

  /**
   * @type {boolean}
   */
  isSponsored() {
    const value = this.getValueForAttribute('sponsored');
    return value;
  }

  setSponsored(value) {
    return this.setField('sponsored', value);
  }

  /**
   * @type {string}
   */
  getStatus() {
    const value = this.getValueForAttribute('status');
    return value;
  }

  setStatus(value) {
    return this.setField('status', value);
  }

  /**
   * @type {ltree}
   */
  getSubjectMatter() {
    const value = this.getValueForAttribute('subject_matter');
    return value;
  }

  setSubjectMatter(value) {
    return this.setField('subject_matter', value);
  }

  /**
   * @type {string[]}
   */
  getTags() {
    const value = this.getValueForAttribute('tags');
    return value;
  }

  setTags(value) {
    return this.setField('tags', value);
  }

  /**
   * @type {datetime}
   */
  getUnpublishedAt() {
    const value = this.getValueForAttribute('unpublished_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  setUnpublishedAt(value) {
    const momentValue = moment(value);
    return this.setField('unpublished_at', momentValue.isValid() ? momentValue : value);
  }

  /**
   * @type {datetime}
   */
  getUpdatedAt() {
    const value = this.getValueForAttribute('updated_at');
    
      const momentValue = moment(value);
      return momentValue.isValid() ? momentValue : value;
    
  }

  /**
   * @type {integer}
   */
  getUpvotes() {
    const value = this.getValueForAttribute('upvotes');
    
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
  }

  setUpvotes(value) {
    return this.setField('upvotes', value);
  }

  // -- Meta Attributes --
  
    /**
     * LEGACY META ACCESSORS
     * @todo These should no longer be used, instead use this.getMeta()[...]
     */
    
      getAutoCountOfAwesomeComments() {
        return this.getMeta().getAutoCountOfAwesomeComments();
      }
    
      getAutoCountOfComments() {
        return this.getMeta().getAutoCountOfComments();
      }
    
      getAutoCountOfContextualizedComments() {
        return this.getMeta().getAutoCountOfContextualizedComments();
      }
    
      getAutoCountOfPastComments() {
        return this.getMeta().getAutoCountOfPastComments();
      }
    
      getAutoCountOfStudentContentComments() {
        return this.getMeta().getAutoCountOfStudentContentComments();
      }
    
      getAutoSumOfCommentSummable() {
        return this.getMeta().getAutoSumOfCommentSummable();
      }
    
      getCommentDashTitles() {
        return this.getMeta().getCommentDashTitles();
      }
    
      getCommentTitleComplexOrder() {
        return this.getMeta().getCommentTitleComplexOrder();
      }
    
      getCommentTitles() {
        return this.getMeta().getCommentTitles();
      }
    
      getCountOfCommentsWithAwesomeAndHorribleCategories() {
        return this.getMeta().getCountOfCommentsWithAwesomeAndHorribleCategories();
      }
    
      getCountOfCommentsWithAwesomeCategories() {
        return this.getMeta().getCountOfCommentsWithAwesomeCategories();
      }
    
      getCountOfDistinctCommentTitles() {
        return this.getMeta().getCountOfDistinctCommentTitles();
      }
    
      getCountOfDistinctCommentTitlesInner() {
        return this.getMeta().getCountOfDistinctCommentTitlesInner();
      }
    
      getCountOfDistinctStudentCommentTitles() {
        return this.getMeta().getCountOfDistinctStudentCommentTitles();
      }
    
      getCountOfFutureComments() {
        return this.getMeta().getCountOfFutureComments();
      }
    
      getCountOfSamesiesComments() {
        return this.getMeta().getCountOfSamesiesComments();
      }
    
      getDistinctCommentTitles() {
        return this.getMeta().getDistinctCommentTitles();
      }
    
      getEchoStudentId() {
        return this.getMeta().getEchoStudentId();
      }
    
      getFirstCommentTitle() {
        return this.getMeta().getFirstCommentTitle();
      }
    
      hasComments() {
        return this.getMeta().hasComments();
      }
    
      getLastCommentTitle() {
        return this.getMeta().getLastCommentTitle();
      }
    
      isNotSponsored() {
        return this.getMeta().isNotSponsored();
      }
    
      getPopulatedByAllOf() {
        return this.getMeta().getPopulatedByAllOf();
      }
    
      getPopulatedByAnyOf() {
        return this.getMeta().getPopulatedByAnyOf();
      }
    
      getPopulatedMetadataFields() {
        return this.getMeta().getPopulatedMetadataFields();
      }
    
    getMeta() {
      if (this.__meta) {
        return this.__meta;
      }
      const meta = this.get('meta', Map());
      this.__meta = {
        
          /**
           * @type {integer}
           */
          getAutoCountOfAwesomeComments() {
            const value = meta.get('auto_count_of_awesome_comments');
            validate(value, 'auto_count_of_awesome_comments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAutoCountOfComments() {
            const value = meta.get('auto_count_of_comments');
            validate(value, 'auto_count_of_comments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAutoCountOfContextualizedComments() {
            const value = meta.get('auto_count_of_contextualized_comments');
            validate(value, 'auto_count_of_contextualized_comments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAutoCountOfPastComments() {
            const value = meta.get('auto_count_of_past_comments');
            validate(value, 'auto_count_of_past_comments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAutoCountOfStudentContentComments() {
            const value = meta.get('auto_count_of_student_content_comments');
            validate(value, 'auto_count_of_student_content_comments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getAutoSumOfCommentSummable() {
            const value = meta.get('auto_sum_of_comment_summable');
            validate(value, 'auto_sum_of_comment_summable');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string}
           */
          getCommentDashTitles() {
            const value = meta.get('comment_dash_titles');
            validate(value, 'comment_dash_titles');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getCommentTitleComplexOrder() {
            const value = meta.get('comment_title_complex_order');
            validate(value, 'comment_title_complex_order');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string}
           */
          getCommentTitles() {
            const value = meta.get('comment_titles');
            validate(value, 'comment_titles');
            return value;
          },
  
          /**
           * @type {integer}
           */
          getCountOfCommentsWithAwesomeAndHorribleCategories() {
            const value = meta.get('count_of_comments_with_awesome_and_horrible_categories');
            validate(value, 'count_of_comments_with_awesome_and_horrible_categories');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfCommentsWithAwesomeCategories() {
            const value = meta.get('count_of_comments_with_awesome_categories');
            validate(value, 'count_of_comments_with_awesome_categories');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDistinctCommentTitles() {
            const value = meta.get('count_of_distinct_comment_titles');
            validate(value, 'count_of_distinct_comment_titles');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDistinctCommentTitlesInner() {
            const value = meta.get('count_of_distinct_comment_titles_inner');
            validate(value, 'count_of_distinct_comment_titles_inner');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfDistinctStudentCommentTitles() {
            const value = meta.get('count_of_distinct_student_comment_titles');
            validate(value, 'count_of_distinct_student_comment_titles');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfFutureComments() {
            const value = meta.get('count_of_future_comments');
            validate(value, 'count_of_future_comments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {integer}
           */
          getCountOfSamesiesComments() {
            const value = meta.get('count_of_samesies_comments');
            validate(value, 'count_of_samesies_comments');
            
      const int = parseInt(value, 10);
      return isNaN(int) ? value : int;
    
          },
  
          /**
           * @type {string}
           */
          getDistinctCommentTitles() {
            const value = meta.get('distinct_comment_titles');
            validate(value, 'distinct_comment_titles');
            return value;
          },
  
          /**
           * @type {string}
           */
          getEchoStudentId() {
            const value = meta.get('echo_student_id');
            validate(value, 'echo_student_id');
            return value;
          },
  
          /**
           * @type {string}
           */
          getFirstCommentTitle() {
            const value = meta.get('first_comment_title');
            validate(value, 'first_comment_title');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          hasComments() {
            const value = meta.get('has_comments');
            validate(value, 'has_comments');
            return value;
          },
  
          /**
           * @type {string}
           */
          getLastCommentTitle() {
            const value = meta.get('last_comment_title');
            validate(value, 'last_comment_title');
            return value;
          },
  
          /**
           * @type {boolean}
           */
          isNotSponsored() {
            const value = meta.get('not_sponsored');
            validate(value, 'not_sponsored');
            return value;
          },
  
          /**
           * @type {string}
           */
          getPopulatedByAllOf() {
            const value = meta.get('populated_by_all_of');
            validate(value, 'populated_by_all_of');
            return value;
          },
  
          /**
           * @type {string}
           */
          getPopulatedByAnyOf() {
            const value = meta.get('populated_by_any_of');
            validate(value, 'populated_by_any_of');
            return value;
          },
  
          /**
           * @type {string[]}
           */
          getPopulatedMetadataFields() {
            const value = meta.get('populated_metadata_fields');
            validate(value, 'populated_metadata_fields');
            return value;
          },
  
      };
      return this.__meta;
    }
  
  // -- Relationships --
  
  /**
   * @type {test_category_v1 (many)}
   */
  getTestCategories() {
    const value = this.getValueForAttribute('test_categories');
    return value;
  }

  // -- Relationship Fields: test_categories --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withTestCategoryRelationshipGetters(data) {
    return {
      getPosition() {
        return data.get('position', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getTestCategoryRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'test_category'], new Map());
    if (!id) {
      return data.map((rel) => this.__withTestCategoryRelationshipGetters(rel));
    } else {
      return this.__withTestCategoryRelationshipGetters(data.get(id, new Map()));
    }
  }

  /**
   * @type {test_comment_v1 (many)}
   */
  getTestComments() {
    const value = this.getValueForAttribute('test_comments');
    return value;
  }

  // -- Relationship Fields: test_comments --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {test_self_unlinked_comment_v1 (many)}
   */
  getTestSelfUnlinkedComments() {
    const value = this.getValueForAttribute('test_self_unlinked_comments');
    return value;
  }

  // -- Relationship Fields: test_self_unlinked_comments --
  // no 'fields' found, skipped generating accessor(s)
  
  /**
   * @type {test_editor_v1 (many)}
   */
  getTestEditors() {
    const value = this.getValueForAttribute('test_editors');
    return value;
  }

  // -- Relationship Fields: test_editors --

  /**
   * Adds relationship field getters to the provided object.
   * @private
   */
  __withTestEditorRelationshipGetters(data) {
    return {
      getMessage() {
        return data.get('message', null);
      },
      getPrimaryEditor() {
        return data.get('primary_editor', null);
      }
    };
  }

  /**
   * @param {string} [id] - A single relationship to retrieve.
   * @returns {Immutable.List|Immutable.Map}
   */
  getTestEditorRelationships(id) {
    const data = this.getIn(['relationshipMeta', 'test_editor'], new Map());
    if (!id) {
      return data.map((rel) => this.__withTestEditorRelationshipGetters(rel));
    } else {
      return this.__withTestEditorRelationshipGetters(data.get(id, new Map()));
    }
  }

}

// -- Empty Model Instance --
let _emptyTestPostModelV1Model = null;

// -- Sort Rules --

const AllowedSorts = fromJS({
  'authored_at': null,
  'content': null,
  'id': null,
  'inserted_at': null,
  'meta.auto_count_of_awesome_comments': null,
  'meta.auto_count_of_comments': null,
  'meta.auto_count_of_contextualized_comments': null,
  'meta.auto_count_of_past_comments': null,
  'meta.auto_count_of_student_content_comments': null,
  'meta.auto_sum_of_comment_summable': null,
  'meta.comment_dash_titles': null,
  'meta.comment_title_complex_order': null,
  'meta.comment_titles': null,
  'meta.count_of_comments_with_awesome_and_horrible_categories': null,
  'meta.count_of_comments_with_awesome_categories': null,
  'meta.count_of_distinct_comment_titles': null,
  'meta.count_of_distinct_comment_titles_inner': null,
  'meta.count_of_distinct_student_comment_titles': null,
  'meta.count_of_future_comments': null,
  'meta.count_of_samesies_comments': null,
  'meta.distinct_comment_titles': null,
  'meta.first_comment_title': null,
  'meta.last_comment_title': null,
  'published_at': null,
  'random_seed': null,
  'rating': null,
  'reviewer_id': null,
  'sponsored': null,
  'status': null,
  'subject_matter': null,
  'unpublished_at': null,
  'updated_at': null,
  'upvotes': null,
});

// -- Include Rules --
// List []

// -- Filter Rules --
const FilterRules = new Map({
  'authored_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'content': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'content_tsvectors_english': new Set(['search_tokens']),
  'content_tsvectors_simple': new Set(['search_phrases']),
  'id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'inserted_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.auto_count_of_awesome_comments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.auto_count_of_comments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.auto_count_of_contextualized_comments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.auto_count_of_past_comments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.auto_count_of_student_content_comments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.auto_sum_of_comment_summable': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.comment_dash_titles': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.comment_title_complex_order': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.comment_titles': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.count_of_comments_with_awesome_and_horrible_categories': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_comments_with_awesome_categories': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_distinct_comment_titles': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_distinct_comment_titles_inner': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_distinct_student_comment_titles': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_future_comments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.count_of_samesies_comments': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'meta.distinct_comment_titles': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.first_comment_title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.last_comment_title': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'meta.populated_metadata_fields': new Set(['null']),
  'metadata': new Set(['null']),
  'migrated_from_ids': new Set(['null']),
  'migrated_from_metadata': new Set(['null']),
  'published_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'random_seed': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'rating': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'reviewer_id': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix','search_keyword']),
  'sponsored': new Set(['exact','not','null']),
  'status': new Set(['exact','substring','case_insensitive_substring','not','null','case_insensitive_prefix']),
  'subject_matter': new Set(['exact','not','null']),
  'tags': new Set(['null']),
  'test_categories_v1': new Set(['associated']),
  'test_comments_v1': new Set(['associated']),
  'test_editors_v1': new Set(['associated']),
  'test_self_unlinked_comments_v1': new Set(['associated']),
  'unpublished_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'updated_at': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
  'upvotes': new Set(['exact','greater_than','less_than','less_than_inclusive','greater_than_inclusive','not','null']),
});
    

// -- Metadata Context Rules --
const MetadataContextRules = fromJS({
  optional: {
    'author_id': 'meta.context.author.id',
    'classroom_statuses': 'meta.context.classroom.statuses',
    'student_id': 'meta.context.student.id',
  },
  required: {
  }
});

// -- Legacy Name Export --
export const TestPost = TestPostModelV1;
