import SimpleMarkdown from 'simple-markdown';
import {
  inlineHtmlRule,
  blockHtmlRule,
  codeHtmlTagRule,
  emptyHtmlTagRule
} from 'generic/MarkdownRendererV2/rules/htmlRules/htmlRules';
import {mathJaxRule, dollarEscapeRule} from 'generic/MarkdownRendererV2/rules/mathJaxRules';
import {collapsibleAreaRule} from 'generic/MarkdownRendererV2/rules/collapsibleArea/collapsibleArea';
import {colorRule} from 'generic/MarkdownRendererV2/rules/colorRule';
import {escapedCharacterRule} from 'generic/MarkdownRendererV2/rules/escapedCharacterRule';
import {supplementRule} from 'generic/MarkdownRendererV2/rules/supplementRules';
import {tooltipTermRule} from 'generic/MarkdownRendererV2/rules/tooltipTerm/tooltipTerm';
import {underlineRule} from 'generic/MarkdownRendererV2/rules/underlineRule';
import {badgeRule} from 'generic/MarkdownRendererV2/rules/design-system/badge/badge';
import textAlignCenterRule from 'generic/MarkdownRendererV2/rules/textAlignCenter/textAlignCenter';
import olTypeRule from 'generic/MarkdownRendererV2/rules/olType/olType';
import buttonRule from 'generic/MarkdownRendererV2/rules/design-system/button/button';
import inlineAreaRule from 'generic/MarkdownRendererV2/rules/inlineArea/inlineArea';
import intentedRule from 'generic/MarkdownRendererV2/rules/indented/indented';
import {questionStemRule} from 'generic/MarkdownRendererV2/rules/questionStemRule/questionStemRule';
import {
  codeBlock,
  inlineCode,
  link,
  table
} from 'generic/MarkdownRendererV2/rules/overrides/simpleMarkdownOverrideRules';

import './markdown-renderer.scss';

const baseRules = {
  ...SimpleMarkdown.defaultRules,
  // Overrides key names must match SimpleMarkdown's defaults
  codeBlock,
  inlineCode,
  link,
  table,
  // Overrides end
  collapsibleAreaRule,
  dollarEscapeRule,
  mathJaxRule,
  inlineHtmlRule,
  blockHtmlRule,
  codeHtmlTagRule,
  emptyHtmlTagRule,
  supplementRule,
  underlineRule,
  escapedCharacterRule,
  tooltipTermRule,
  colorRule,
  inlineAreaRule,
  badgeRule,
  textAlignCenterRule,
  buttonRule,
  olTypeRule,
  intentedRule,
  questionStemRule
};

/**
 * There are issues between the markdown renderer and google translate. According to bugsnag, we are only hitting this issue
 * in assignments. From investigation, it looks like google translate isn't causing a re-render and can't clean up some specific
 * markup scenarios as documented in the issue thread.
 * {@link https://github.com/albert-io/project-management/issues/4950 Github Issue}
 * {@link https://github.com/facebook/react/issues/11538#issuecomment-390386520 React issue thread}
 * To check if we are currently translating:
 * `const isTranslating = !!global.document.getElementsByClassName('translated-ltr').length;`
 *
 * Thoughts: Currently, adding the below to `rules` stops erros from appearing
 * but does not clean up previously rendered text nodes correctly
 *
 *  text: {
 *     ...SimpleMarkdown.defaultRules.text,
 *    react: (node, output, state) => {
 *     // I think Google translate is having difficulty with cleaning up on rerenders due to duplicate keys
 *      return <span key={`${node.content}-${state.key}-${Math.random()}`}>{node.content}</span>
 *     }
 *   }
 *
 *  The only working solution for the ticketed issue is to force the `MarkdownBlock` component to rerender on every change.
 *  To do this, we added a key to `Prompt` in `ContentBlocks.react` and in `HeadingBar.react` on the markdown components.
 *  This means that we did not fix the issue at the renderer level and should keep an eye out in bugsnag to see if it pops up
 *  in other areas of the application.
 *
 */

function makeParser(additionalRules, whitelist) {
  let rules = {
    ...baseRules,
    ...additionalRules
  };
  if (whitelist) {
    rules = whitelist.reduce((acc, rule) => {
      acc[rule] = rules[rule];
      return acc;
    }, {});
  }
  const rawBuiltParser = SimpleMarkdown.parserFor(rules);
  const parser = (source) => {
    const blockSource = `${source}\n\n`;
    return rawBuiltParser(blockSource, {inline: false});
  };
  return {
    makeSyntaxTree: parser,
    reactOutput: SimpleMarkdown.reactFor(SimpleMarkdown.ruleOutput(rules, 'react')),
    htmlOutput: SimpleMarkdown.htmlFor(SimpleMarkdown.ruleOutput(rules, 'html'))
  };
}

export {makeParser};
